<template>
  <v-dialog
      scrollable
      max-width="800"
      v-model="dialog"
  >
    <v-card :loading="loading">
      <v-card-title class="card-header" style="min-height: 70px">
        <span>
          {{ this.$t('generic.lang_orderDetails') }}
        </span>
        <v-spacer/>
        <v-btn v-if="$store.getters['permissions/checkPermission']('eventbee_void_order')" :loading="loading || loadingEmail" :disabled="loading || loadingEmail" color="error" @click="cancelTicket">
          {{ this.$t('eventbee.lang_cancelTickets') }}
          <font-awesome-icon class="mt-0 mx-1" style="font-size: 18px" :icon="['fal','ban']"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-4 px-0">
        <v-container v-if="order" fluid>
          <v-row>
            <v-col cols="6">
              <div>
                <font-awesome-icon
                    :icon="['fal', 'user']"
                    class="icons-size text-muted"
                />
                <strong class="primary--text ml-1">{{
                    customer.first_name + ' ' + customer.last_name
                  }}</strong>
              </div>
              <div v-if="customer.telPrivate">
                <font-awesome-icon
                    :icon="['fal', 'phone']"
                    class="icons-size text-muted"
                />
                <span class="text--black ml-1">{{
                    customer.telPrivate
                  }}</span>
              </div>
              <br/>
              <div v-if="note">
                <font-awesome-icon
                    :icon="['fal', 'sticky-note']"
                    class="icons-size text-muted"
                />
                <span class="text--black ml-1 font-weight-bold">{{
                    order.note
                  }}</span>
              </div>
            </v-col>
            <v-col cols="6">
              <span class="text--black ml-1 font-weight-bold">{{
                  order.eventName
                }}</span><br/>
              <span class="text-muted ml-1">{{
                  order.groupName
                }}</span>
              <br/>
              <br/>
              <br/>
              <div>
                <v-icon class="text-muted" size="20">
                  mdi-calendar
                </v-icon>
                <span class="text--black ml-1 font-weight-bold">{{
                    order.eventDate + ' ' + $t('generic.lang_from') + ' ' + order.fromTime + ' ' + $t('generic.lang_to') + ' ' + order.toTime
                  }}</span>
              </div>

              <div v-if="order.slot_from || order.slot_to || order.arrival_time">
                <font-awesome-icon
                    :icon="['fal', 'clock']"
                    class="icons-size text-muted"
                />
                <span v-if="order.arrival_time" class="text--black ml-1 font-weight-bold">
                  {{ order.arrival_time }}
                </span>
                <span v-else class="text--black ml-1 font-weight-bold">{{
                    (order.slot_from?order.slot_from:'') + ' ' +(order.slot_to?order.slot_to:'')
                  }}</span>
              </div>

              <div>
                <font-awesome-icon
                    :icon="['fal', 'users']"
                    class="icons-size text-muted"


                />
                <span class="font-weight-bold ml-1">{{
                    order.numberOfPersons + ' ' + $t('generic.lang_persons')
                  }}</span>
              </div>

              <div>
                <font-awesome-icon
                    :icon="['fal', 'ticket']"
                    class="icons-size text-muted"
                />
                <span
                    class="font-weight-bold ml-1">{{ order.numberOfTickets + ' ' + $t('eventbee.lang_tickets') }}</span>
              </div>

              <div v-if="attribute">
                <font-awesome-icon
                    :icon="['fal', 'tag']"
                    class="icons-size text-muted"


                />
                <span class="text--black ml-1 font-weight-bold" v-html="attribute"></span>
              </div>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-simple-table>
                <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('generic.lang_name') }}
                  </th>
                  <th class="text-right">
                    {{ $t('erp.lang_quantity') }}
                  </th>
                  <th class="text-right">
                    {{ $t('generic.lang_price') }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(ticket, key) in tickets"
                    :key="key"
                >
                  <td v-if="ticket.tableName">{{ ticketPrices[key].name }} {{ ticket.table || ticket.tableName? $t('generic.lang_table')+' '+(ticket.table || ticket.tableName):'' }}</td>
                  <td v-else-if="ticket.rowNumber">{{ ticketPrices[key].name }} {{ ticket.rowNumber? $t('eventbee.lang_row')+'-'+ticket.rowNumber+','+$t('eventbee.lang_seat')+' '+ticket.seatNumber:''}}</td>
                  <td v-else>{{ ticket.name }}</td>
                  <td class="text-right">{{ ticket.selected_quantity }}</td>
                  <td class="text-right">{{ Number(ticket.value) | currency }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="6" align="right"/>
            <v-col cols="6" align="right">
              <h4>{{ $t('generic.lang_total') + ' ' }}{{ order.total | currency }}</h4>
              <h4>{{ order.paymentType }}</h4>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="card-footer py-0">
        <v-btn
            :disabled="loading || loadingEmail"
            @click="dialog = false"
            color="error"
            text
        >
          {{ this.$t('generic.lang_close') }}
        </v-btn>

        <v-spacer/>

        <v-btn :loading="loading || loadingEmail" :disabled="loading || loadingEmail" color="primary" @click="reSendInvoiceAndTicketsEmail">
          {{ this.$t('eventbee.lang_resendInvoiceAndTickets') }}
          <font-awesome-icon class="mt-0 mx-1" style="font-size: 18px" :icon="['fal','envelope']"/>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from '@/config';
import {Events} from '@/plugins/events';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faAt,
  faCalendar,
  faCalendarAlt,
  faCalendarEdit,
  faCalendarTimes,
  faCheck,
  faCheckSquare,
  faClipboard,
  faClock,
  faCopy,
  faEdit,
  faEnvelope,
  faHashtag,
  faInfoSquare,
  faKeynote,
  faPaperPlane,
  faPhone,
  faStickyNote,
  faTrash,
  faUser,
  faUsers,
  faWindowClose,
  faTicket,
  faTag,
    faBan,
} from '@fortawesome/pro-light-svg-icons';

library.add(
    faUsers,
    faPaperPlane,
    faClipboard,
    faClock,
    faCalendarAlt,
    faKeynote,
    faAt,
    faCalendar,
    faUser,
    faPhone,
    faCopy,
    faCheckSquare,
    faTrash,
    faInfoSquare,
    faCalendarEdit,
    faCalendarTimes,
    faWindowClose,
    faEnvelope,
    faEdit,
    faStickyNote,
    faTicket,
    faCheck,
    faTag,
    faBan,
    faHashtag
);

export default {
  name: "EventbeeOrderDetailsComponent",
  components: {
    FontAwesomeIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: '',
    }
  },
  watch: {
    dialog(v) {
      if (v) {
        this.getOrderDetails()
      } else {
        this.$emit('close')
      }
    }
  },
  data() {
    return {
      loading: false,
      loadingEmail: false,
      order: null,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    customer() {
      return this.order.customerData || {};
    },
    tickets() {
      return this.order.price || [];
    },
    ticketPrices() {
      return this.order.prices || [];
    },
    attribute() {
      return this.order.attribute && this.order.attribute !== '' ? this.order.attribute : null;
    },
    note() {
      return this.order.note && this.order.note !== '' ? this.order.note : null;
    },
    phoneNumber() {
      return this.customer.telPrivate && this.customer.telPrivate !== '' ? this.customer.telPrivate : null;
    }
  },
  methods: {
    getOrderDetails() {
      this.loading = true;
      this.axios
          .post(ENDPOINTS.EVENTBEE.ORDER_LIST.ORDER_DETAILS, {
            uuid: this.uuid,
          })
          .then((res) => {
            if (res.data.status === 'success') {
              this.order = {...res.data.data};
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: err.message,
              color: 'error',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    cancelTicket() {
      this.$swal({
        title: this.$t('generic.lang_cancel'),
        text: this.$t('erp.lang_deleteOrderText'),
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: this.$t('generic.lang_no'),
        confirmButtonText: this.$t('generic.lang_yes'),
        preConfirm: () => {
          this.loading = true;

          this.axios
              .post(ENDPOINTS.EVENTBEE.ORDERS.CANCEL, {
                uuid: this.uuid,
              })
              .then((res) => {
                if (res.data.status) {
                  this.$emit('updateOrders');
                  Events.$emit('showSnackbar', {
                    message: this.$t('generic.lang_success'),
                    color: 'success',
                  });
                  this.dialog = false;
                } else {
                  Events.$emit('showSnackbar', {
                    message:
                        res.data?.message ?? this.$t('generic.lang_errorOccurred'),
                    color: 'error',
                  });
                }
              })
              .catch((err) => {
                Events.$emit('showSnackbar', {
                  message: err.message,
                  color: 'error',
                });
              })
              .finally(() => {
                this.loading = false;
              });
        },
      });
    },
    reSendInvoiceAndTicketsEmail(){
      this.loadingEmail = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.SUBDOMAIN.GET).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          if(res.data.hasDomains){
            let domain=res.data.domains.find(d=>d.service === 'eventbee')
            if(domain){
              let eventbeeSubDomain = domain.domain;

              this.$swal({
                title: this.$t('eventbee.lang_resendInvoiceAndTickets'),
                text: this.$t('eventbee.lang_resendInvoiceAndTicketsMSG'),
                icon: "warning",
                confirmButtonText: this.$t('generic.lang_yes'),
                cancelButtonText: this.$t('generic.lang_cancel'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  this.loadingEmail = true;
                  let serviceApiAxios = this.axios.create({
                    baseURL: process.env.VUE_APP_SERVICE_API_HOST
                  });

                  delete serviceApiAxios.defaults.headers.common['3POSAPITOKEN'];
                  delete serviceApiAxios.defaults.headers.common['3POSBS'];
                  delete serviceApiAxios.defaults.headers.common['3POSBSID'];
                  serviceApiAxios.post(`eventbee/orders/resend-email/${this.order.invoiceUUID}?service_domain=${eventbeeSubDomain.split('.')[0]}`,
                  ).then((res) => {
                    if (res.status === 200) {
                      Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_ActionSuccessful"),
                        color: "success"
                      });

                    } else {
                      Events.$emit("showSnackbar", {
                        message: res.data.msg || this.$t('generic.lang_errorOccurred'),
                        color: "error"
                      });
                    }
                  }).catch(err => {
                    Events.$emit("showSnackbar", {
                      message: err.message,
                      color: "error"
                    });
                  }).finally(() => {
                    this.loadingEmail = false;
                  });
                },
                allowOutsideClick: () => !this.$swal.isLoading,
              });
            }else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_noDomainHasBeenFound'),
                color: "error"
              });
            }
          }else{
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_noDomainHasBeenFound'),
              color: "error"
            });
          }
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingEmail = false;
      })
    }
  }
}
</script>

<style scoped>
.icons-size {
  font-size: 16px !important;
}
</style>